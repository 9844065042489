$(document).ready(function () {

    // HEADER & NAVIGATION
    // =============================
    var $html = $('html');
    var $hamburger = $('.header .hamburger');
    var $menu = $('.menu');
    var $overlayMenu = $(".overlay-menu");
    var $subMenu = $('.menu .sub-menu');


    function toggleMenu() {
        if (!$menu.hasClass('animate')) {
            $hamburger.addClass("close");
            $html.css({
                overflow: 'hidden'
            });
            $menu.addClass('animate');
            $overlayMenu.addClass("active");
        } else {
            $hamburger.removeClass("close");
            $html.css({
                overflow: 'auto',
                overflowY: 'scroll'
            });
            $menu.removeClass('animate');
            $overlayMenu.removeClass("active");
        }
    }

    $hamburger.on('click', function (e) {
        e.preventDefault();
        toggleMenu();
    });

    $overlayMenu.on('click', function (e) {
        e.preventDefault();
        toggleMenu();
    });





    // BOX CONTAINER
    // =============================
    var $boxContainer = $('.box-container');

    function loadSuperImg($container) {
        $container.find('.super-img img').each(function () {
            var self = this;
            var image = new Image();
            image.onload = function () {
                $(self).parent('.super-img').addClass('loaded');
            }
            image.src = $(this).attr('src');
        });
    }


    loadSuperImg($boxContainer);

    // $('.loader-wrap').hide(0);
    $boxContainer.addClass('animated fadeIn visible');


    // DETAIL PAGE
    // ================================
    var $detail = $('.detail-block');





    // SEARCH
    // =============================
    var SEARCH_URL = '/sample-search-results.html';
    var $searchContainer = $('.search-container'),
        $searchBtn = $('.search-btn'),
        $search = $('.search'),
        $searchInput = $('.search input'),
        $searchCloseBtn = $search.find('.close-btn'),
        $main = $('.main');

    function eliminateDuplicates(arr) {
        var i,
            len = arr.length,
            out = [],
            obj = {};

        for (i = 0; i < len; i++) {
            obj[arr[i]] = 0;
        }
        for (i in obj) {
            out.push(i);
        }
        return out;
    }

    function debounce(fn, threshold) {
        var timeout;
        return function debounced(e) {
            if (timeout) {
                clearTimeout(timeout);
            }
            function delayed() {
                fn(e);
                timeout = null;
            }
            timeout = setTimeout(delayed, threshold || 100);
        };
    }

    function getAllSearchResults() {
        $.ajax({
            url: SEARCH_URL,
            dataType: 'html',
            cache: true,
            success: function (data) {
                if (!data || !$searchContainer.is(':empty')) {
                    return;
                }
                fuzzy.analyzeSubTerms = true;
                var allTags = [],
                    suggestionMaxResult = 3,
                    $suggestionList = $search.find('ul'),
                    $searchItems,
                    qsRegex,
                    $grid;

                $searchContainer.html(data);
                $searchItems = $searchContainer.children('.box');
                $searchItems.each(function () {
                    var title = $(this).find('h3').text();
                    var tags = $(this).attr('data-tags').split(',');
                    for (var i = 0; i < tags.length; i++) {
                        var tag = $.trim(tags[i]);
                        allTags.push(tag);
                    }
                    allTags.push(title);
                })

                eliminateDuplicates(allTags);

                $grid = $searchContainer;

                loadSuperImg($searchContainer);


                var doFilterSuggestions = function () {
                    var query = $searchInput.val(),
                        filterResult = [],
                        i;

                    // removing all child elements the easy way
                    $suggestionList.html('');

                    if (query === '') {
                        return;
                    }

                    for (i = 0; i < allTags.length; i++) {
                        filterResult[i] = fuzzy(allTags[i], query);
                    }

                    filterResult.sort(fuzzy.matchComparator);

                    for (i = 0; i < filterResult.length && i < suggestionMaxResult; i++) {
                        var suggestion = filterResult[i],
                            el = document.createElement('li');

                        el.dataset.score = suggestion.score;
                        el.dataset.term = suggestion.term;
                        el.innerHTML = suggestion.highlightedTerm;

                        // if (i === 0) {
                        //   el.classList.add('selected');
                        // }

                        $suggestionList[0].appendChild(el);
                    }
                };

                var getSelectedNode = function () {
                    return $suggestionList.find('.selected')[0];
                };

                var useSelectedSuggestion = function () {
                    var selectedNode = getSelectedNode();
                    if (selectedNode !== null) {
                        $searchInput.val(selectedNode.dataset.term);
                        // $searchInput.keyup();
                    }
                };

                var moveSelection = function (down) {
                    var selectedNode = getSelectedNode(),
                        newSelectedNode = null;

                    if (selectedNode) {
                        if (down) {
                            newSelectedNode = selectedNode.nextSibling;
                        } else {
                            newSelectedNode = selectedNode.previousSibling;
                        }
                    } else {
                        if (down) {
                            newSelectedNode = $suggestionList.find('li:first-child')[0];
                        } else {
                            newSelectedNode = $suggestionList.find('li:last-child')[0];
                        }
                    }

                    if (newSelectedNode !== null) {
                        if (typeof selectedNode !== 'undefined' && selectedNode) {
                            selectedNode.classList.remove('selected');
                        }
                        newSelectedNode.classList.add('selected');
                    }
                };

                var doIsotope = function (quitSearch) {
                    if (!$searchContainer.is(':visible')) {
                        $searchContainer.css('display', 'flex');
                        $boxContainer.hide(0);
                        $detail.hide(0);
                    }
                    qsRegex = new RegExp($searchInput.val(), 'gi');
                    $searchItems.each(function () {
                        var isMatch = qsRegex ? $(this).text().match(qsRegex) || $(this).attr('data-tags').match(qsRegex) : true;
                        if (!isMatch) {
                            $(this).hide();
                        } else {
                            $(this).show();
                        }
                    });

                    loadSuperImg($searchContainer);

                    if (quitSearch) {
                        $suggestionList.html('');
                    }
                };

                $searchInput.keyup(debounce(function (e) {

                    if (e.which === 13) { // enter
                        // useSelectedSuggestion();
                        doFilterSuggestions();
                        // $searchInput.keyup();
                        doIsotope(true);
                        $html.removeClass('has-search-suggestions');
                    } else if (e.which === 40) { // down
                        moveSelection(true);
                        useSelectedSuggestion();
                        doIsotope();
                        // doFilterSuggestions();
                    } else if (e.which === 38) { // up
                        moveSelection(false);
                        useSelectedSuggestion();
                        doIsotope();
                        // doFilterSuggestions();
                    } else if (e.which === 27) { // ESC
                        $suggestionList.html('');
                        $searchInput.val('');
                        $searchBtn.click();
                    } else {
                        doFilterSuggestions();
                        doIsotope();
                        $html.addClass('has-search-suggestions');
                    }

                    if (!$searchInput.val()) {
                        $searchContainer.hide(0);
                        $boxContainer.show(0);
                        $detail.show(0);
                        $html.removeClass('has-search-suggestions');
                    }

                }, 200));

                $(document).on('click', '.search ul li', function () {
                    $(this).siblings().removeClass('selected');
                    $(this).addClass('selected');
                    useSelectedSuggestion();
                    doIsotope(true);
                    $html.removeClass('has-search-suggestions');
                });


            }
        });

    }

    $searchBtn.on('click', function (e) {
        e.preventDefault();
        if (innerWidth < 992) {
            toggleMenu();
        }

        if (!$search.hasClass('visible')) {
            $search.addClass('visible animated slideInDown');
            $main.removeClass('animationClose animationClose2');
            if (!$subMenu.is(':visible')) {
                $main.addClass('animation');
            } else {
                $main.addClass('animation2');
                $search.addClass('has-margin-top');
            }
            if (innerWidth >= 992) {
                $searchBtn.addClass('close2');
            }
            $searchInput.focus();
            getAllSearchResults();
        } else {
            $search.removeClass('slideInDown').addClass('slideOutUp');
            $main.removeClass('animation animation2');
            if (!$subMenu.is(':visible')) {
                $main.addClass('animationClose');
            } else {
                $main.addClass('animationClose2');
            }
            setTimeout(function () {
                $search.removeClass("visible animated slideOutUp");
            }, 1000);
            $searchBtn.removeClass('close2');
            $searchContainer.hide(0);
            $boxContainer.show(0);
            $detail.show(0);
            $searchInput.val('');
            $('.search ul').html('');
        }

    });

    $searchCloseBtn.on('click', function (event) {
        event.preventDefault();
        $search.removeClass('slideInDown').addClass('slideOutUp');
        $main.removeClass('animation animation2');
        if (!$subMenu.is(':visible')) {
            $main.addClass('animationClose');
        } else {
            $main.addClass('animationClose2');
        }
        setTimeout(function () {
            $search.removeClass("visible animated slideOutUp");
        }, 1000);
        $searchBtn.removeClass('close2');
        if (Modernizr.mq('(min-width: 992px)')) {
            $searchContainer.hide(0);
            $boxContainer.show(0);
            $detail.show(0);
            $searchInput.val('');
        }
    });

    //Share button
    $('.act_nxt').on('click', function (e) {
        e.stopPropagation();
        $('.act_nxt .box_sub').toggleClass('shown');
    });

    $('.act_nxt .box_sub').on('click', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', function () {
        $('.act_nxt .box_sub').removeClass('shown');
    });

    //print button
    $('.act_print').on('click', function (e) {
        e.preventDefault();
        window.print();
    });

});